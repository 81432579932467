.stepper-wrapper {
  font-family: Arial;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 5px; */
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  font-size: 8px;
}

.stepper-item + .stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  font-size: 8px;
  margin-left: 8px;
}

@media (min-width: 471px) and (max-width:1268px) {
  .stepper-item {
    font-size: 10px;
  }

  .stepper-item + .stepper-item {
      margin-left: 25px;
      font-size: 10px;
  }
}

@media (min-width: 321px) and (max-width:470px) {
  .stepper-item {
    font-size: 9px;
  }

  .stepper-item + .stepper-item {
      margin-left: 10px;
      font-size: 9px;
  }
}

@media (max-width: 320px) {
  .stepper-item {
    font-size: 7px;
  }

  .stepper-item + .stepper-item {
      margin-left: 5px;
      font-size: 7px;
  }
}

.step-button{
  position: absolute;
  width: 100%;
  color: transparent !important;
  border: 0;
  cursor: pointer;
  background: transparent !important;
  z-index: 10;
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 12.5px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 12.5px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #ccc;
  /* margin-bottom: 6px; */
}

.svg-width {
  font-size: 16px;
}

.img-width {
  height: 16px;
  width: 16px;
}

.no-label{
  color: transparent;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: #4bb543;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #4bb543;
  width: 150%;
  top: 12.5px;
  left: 50%;
  z-index: 3;
}

.stepper-item.errored .step-counter {
  background-color: #ff5e50;
}

.stepper-item.errored::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ff5e50;
  width: 150%;
  top: 12.5px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}
